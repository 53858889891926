import { createStore, applyMiddleware, compose } from 'redux';
import Reducer from './reducer';
import thunk from 'redux-thunk';

const initialState = {
  interviews: {},
  pastInterviews: {},
}

const isBrowser = typeof window !== "undefined"
const composeEnhancers = isBrowser ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = createStore(
  Reducer,
  initialState,
  compose(
    composeEnhancers(
      applyMiddleware(thunk))
    )
);

export default store;
