const initialState = {
  video: {},
  interviews: {},
  pastInterviews: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_YT_INTERVIEWS':
          return { ...state, interviews: action.payload }
        case 'SET_YT_PAST_INTERVIEWS':
          return { ...state, pastInterviews: action.payload }

        default: return state
    }
}
export default reducer;
